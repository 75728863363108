import { Center, Card, Text, Title, Divider, Stack, List } from "@mantine/core";

export interface PricingCardProps {
  title: string;
  price: string;
  description: string;
  breakdown?: string[];
}

export default function PricingCard(props: PricingCardProps) {
  const { title, description, breakdown = [] } = props;
  return (
    <Card shadow="sm" radius="md" style={{ minHeight: "22em" }}>
      <Card.Section p="lg">
        <Stack>
          <Center>
            <Title order={1}>{title}</Title>
          </Center>
        </Stack>
      </Card.Section>

      <Card.Section p="xl" pt="xs">
        <Stack>
          {description && <Text size="md">{description}</Text>}
          <List size="lg">
            {breakdown.map((bullet) => {
              return <List.Item>{bullet}</List.Item>;
            })}
          </List>
        </Stack>
      </Card.Section>
    </Card>
  );
}
