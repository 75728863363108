import {  
  Grid,  
  Title,
} from "@mantine/core";
import PricingCard from "./PricingCard";

export default function Pricing() {
  return (
    <>
      <Title>Pricing</Title>
      <Grid>
        <Grid.Col xs={12} sm={4}>
          <PricingCard
            title="Partner"
            price="6%"
            description="Be part of our platform"
            breakdown={[
              "No Contract",
              "$0 setup fee",
              "6% commision",
              "Stripe 2.9% + 30¢ fee on cards payments",
              "2.25% on ATH Móvil payments",
            ]}
          />
        </Grid.Col>
        <Grid.Col xs={12} sm={4}>
          <PricingCard
            title="Private Label"
            price="15%"
            description="Have your own app"
            breakdown={[
              "No Contract",                      
              "$500 setup fee",
              "15% commision",
              "Stripe 2.9% + 30¢ fee on cards payments",
              "2.25% on ATH Móvil payments",
            ]}
          />
        </Grid.Col>
        <Grid.Col xs={12} sm={4}>
          <PricingCard
            title="Point of Sale"
            price="$80 montlhy"
            description="Coming soon..."
            breakdown={[
              "No Contract",
              "$0 setup fee",
              "$80 montlhy",
              "0% commision",
            ]}
          />
        </Grid.Col>
      </Grid>
    </>
  );
}
