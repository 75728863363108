import {
  AppShell,  
  MantineProvider,
} from "@mantine/core";
import AppRouter from "./AppRouter";

export default function App() {
  return (
    <MantineProvider
      withGlobalStyles
      withNormalizeCSS
      theme={{
        // Override any other properties from default theme
        fontFamily: "Space Grotesk, sans serif",        
        colors: {
          apunto: ['#FE3B1F']
        },        
      }}
    >
      <AppShell
        padding="md"        
        styles={(theme) => ({
          main: {
            color: 'white',
            backgroundColor: theme.colors.apunto[0],
          },
        })}
      >
        <AppRouter />
      </AppShell>
    </MantineProvider>
  );
}
