import {
  Container,
  Stack,
  Image,
  Text,
  Space,
  Center,
  Title,
  Group,
  Box,
} from "@mantine/core";

import DevelopmentTimeline from "../../components/DevelopmentTimeline";
import Pricing from "../../components/Pricing";

import "./index.css";

export default function Home() {
  return (
    <Container>
      <Center>
        <Stack>
          <Center mt="4em">
            <Image
              src="images/apunto-white.png"
              alt="Apunto logo"
              sx={{ maxWidth: "80vw" }}
            />
          </Center>
          <Space />
          <Box p="md">
            <Text size="3em" fw="bold" ta="center">
              Your pick up solution for your business
            </Text>
          </Box>
          <Space />
          <Pricing />
          <Space />
          <Center p="md">
            <Image
              src="images/manecilla-rockera-con-borde.png"
              alt="Apunto logo"
              height={"20em"}
              width={"20em"}
            />
          </Center>
          <Space />
          <Stack>
            <Title align="center">Want to know more?</Title>
            <Group position="center">
              <Image src="images/manecilla.png" alt="Apunto logo" width={40} />
              <Text align="center" weight={500}>
                <a className="" href="mailto:info@apunto.app">
                  info@apunto.app
                </a>
              </Text>
            </Group>
          </Stack>
        </Stack>
      </Center>
    </Container>
  );
}
